<template>
  <r-page page-title="Package">
    <template #page-header>
      <h2 class="page-title">
        {{ route === 'choose' ? 'Choose Package' : 'Upgrade Package' }}
      </h2>
    </template>
    <template #action-bar>
      <b-breadcrumb separator="has-succeeds-separator" size="is-small">
        <b-breadcrumb-item tag="router-link" to="/subscription">
          Billing
        </b-breadcrumb-item>
        <b-breadcrumb-item
          tag="router-link"
          to="/package/choose"
          active
          v-if="route === 'choose'"
        >
          Choose Package
        </b-breadcrumb-item>
        <b-breadcrumb-item
          tag="router-link"
          to="/package/upgrade"
          active
          v-if="route === 'upgrade'"
        >
          Upgrade Package
        </b-breadcrumb-item>
      </b-breadcrumb>
    </template>
    <template #page-content>
      <div class="container">
        <ChoosePackage @choosePackage="onChoosePackage" />
      </div>
    </template>
  </r-page>
</template>

<script>
import ChoosePackage from '../Payment/components/ChoosePackage.vue'

export default {
  components: { ChoosePackage },
  data() {
    return {
      route: '',
    }
  },
  mounted() {
    this.getPackageRoute()
  },
  methods: {
    getPackageRoute() {
      this.route = this.$route.params.id
    },
    onChoosePackage(packageId) {
      this.$router.push(`/package-detail?id=${packageId}&mode=${this.route}`)
    },
  },
}
</script>
